/*
 *   File : config.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Config file for the template
 *   Version : 1.0.0
 *   Created : 10-11-2021
 */

/*import side bar icons*/
import Dashboard from './../assets/images/sidebar/dashboard.svg';
import Users from './../assets/images/sidebar/users.svg';
import Transations from './../assets/images/sidebar/transations.svg';
import Risk from './../assets/images/sidebar/risk.svg';
import ManageAllocation from "./../assets/images/sidebar/manage-allocation.svg";
import Returns from "./../assets/images/sidebar/returns.svg";
import ManageContent from './../assets/images/sidebar/manage-content.svg';
import Pairs from './../assets/images/sidebar/pairs.svg';
import App from './../assets/images/sidebar/app.svg';
import Banner from './../assets/images/sidebar/banner.svg';
import MatureFund from './../assets/images/sidebar/mature-fund.svg';

import DashboardActive from './../assets/images/sidebar/dashboard-active.svg';
import UsersActive from './../assets/images/sidebar/users-active.svg';
import TransationsActive from './../assets/images/sidebar/transation-active.svg';
import RiskActive from './../assets/images/sidebar/risk-active.svg';
import ManageAllocationActive from "./../assets/images/sidebar/manage-allocation-active.svg"
import ReturnsActive from "./../assets/images/sidebar/returns-active.svg";
import ManageContentActive from './../assets/images/sidebar/manage-content-active.svg';
import PairsActive from './../assets/images/sidebar/pairs-active.svg';
import AppActive from './../assets/images/sidebar/app-active.svg';
import BannerActive from './../assets/images/sidebar/banner-active.svg';
import MatureFundActive from './../assets/images/sidebar/mature-fund-active.svg';

import DashboardCollapseActive from './../assets/images/sidebar/dashboard-collapse-active.svg';
import UsersCollapseActive from './../assets/images/sidebar/users-collapse-active.svg';
import TransationsCollapseActive from './../assets/images/sidebar/transation-collapse-active.svg';
import RiskCollapseActive from './../assets/images/sidebar/risk-collapse-active.svg';
import ManageAllocationCollapse from "./../assets/images/sidebar/manage-allocation-collapse.svg";
import ReturnsCollapseActive from "./../assets/images/sidebar/returns-collapse.svg";
import ManageContentCollapse from './../assets/images/sidebar/manage-content-collapse.svg';
import PairsCollapse from './../assets/images/sidebar/pairs-collapse.svg';
import AppCollapseActive from './../assets/images/sidebar/app-collapse-active.svg';
import BannerCollapse from './../assets/images/sidebar/banner-collapse.svg';
import MatureFundCollapse from './../assets/images/sidebar/mature-fund-collapse.svg';

/*import footer images*/
import FooterLogo from './../assets/images/navigation/outer-footer-logo.svg';

/*import header images*/
import OuterHeaderLogo from './../assets/images/navigation/inner-logo.svg';

/*----Meta -----*/
const Metadata = { title: 'Evo-admin-template' }

// prod api url = https://6d5tbkabq5.execute-api.ap-south-1.amazonaws.com/prod/api/v1/admin

/*----- Sidebar ------ */
const Sidebar = [
    {
        name: 'Dashboard',
        icon: Dashboard,
        link: '/',
        header: 'Dashboard',
        collapseActiveIcon: DashboardCollapseActive,
        activeIcon: DashboardActive,
    },
    {
        name: 'Users',
        icon: Users,
        link: '/users',
        header: 'Users',
        collapseActiveIcon: UsersCollapseActive,
        activeIcon: UsersActive
    },
    {
        name: 'Orders list',
        icon: Transations,
        link: '/orders',
        header: 'Orders',
        // collapseActiveIcon: RevenueCollapseActive,
        // activeIcon: RevenueActive
        collapseActiveIcon: TransationsCollapseActive,
        activeIcon: TransationsActive,
    },
    {
        name: 'Pending Orders list',
        icon: Transations,
        link: '/pending-orders',
        header: 'Pending Orders',
        // collapseActiveIcon: RevenueCollapseActive,
        // activeIcon: RevenueActive
        collapseActiveIcon: TransationsCollapseActive,
        activeIcon: TransationsActive,
    },
    {
        name: 'Manage funds',
        icon: Risk,
        link: '/funds',
        header: 'Manage scheme',
        collapseActiveIcon: RiskCollapseActive,
        activeIcon: RiskActive,
    },
    {
        name: 'Manage matur. f',
        icon: MatureFund,
        link: '/target-maturity',
        header: 'Manage maturity fund',
        collapseActiveIcon: MatureFundCollapse,
        activeIcon: MatureFundActive,
    },
    {
        name: 'Manage allocation',
        icon: ManageAllocation,
        link: '/allocation',
        header: 'Manage allocation',
        collapseActiveIcon: ManageAllocationCollapse,
        activeIcon: ManageAllocationActive,
    },
    {
        name: 'Manage returns',
        icon: Returns,
        link: '/returns',
        header: 'Manage returns',
        collapseActiveIcon: ReturnsCollapseActive,
        activeIcon: ReturnsActive,
    },
    {
        name: 'App notifications',
        icon: App,
        link: '/notifications',
        header: 'Send app notifications',
        collapseActiveIcon: AppCollapseActive,
        activeIcon: AppActive
    },
    {
        name: 'Manage content',
        icon: ManageContent,
        link: '/content',
        header: '',
        collapseActiveIcon: ManageContentCollapse,
        activeIcon: ManageContentActive
    },
    {
        name: 'Value pairs',
        icon: Pairs,
        link: '/value-pairs',
        header: 'value pairs',
        collapseActiveIcon: PairsCollapse,
        activeIcon: PairsActive
    },
    {
        name: 'Manage banner',
        icon: Banner,
        link: '/banner',
        header: 'Manage home banner',
        collapseActiveIcon: BannerCollapse,
        activeIcon: BannerActive
    },
    {
        name: 'Version history',
        icon: Risk,
        link: '/version-history',
        header: 'Version history',
        collapseActiveIcon: RiskCollapseActive,
        activeIcon: RiskActive,
    },
    {
        name: 'Rebalance history',
        icon: Risk,
        link: '/rebalance-history',
        header: 'Rebalance history',
        collapseActiveIcon: RiskCollapseActive,
        activeIcon: RiskActive,
    },

]
/*-------Outer Header-----*/
const OuterHeader = {
    OuterHeaderLogo: OuterHeaderLogo,
}

/*----- Footer ----- */
const Footer = {
    footerLogo: FooterLogo,
    footerCopyRight: `©  ${new Date().getFullYear()} Mira Pvt. Ltd.`,
    supportLink: 'support@mira.com',
    poweredby: 'evoqueinnovativelab.com',
    footerItems: [
        {
            name: 'Home',
            link: '#',
        },
        {
            name: 'Terms',
            link: '#',
        },
        {
            name: 'Privacy',
            link: '#',
        },
    ]
}

var devconfig = {
    sidebar: Sidebar,
    footer: Footer,
    outerHeader: OuterHeader,
    meta: Metadata,
    admin: process.env.REACT_APP_API_ENDPOINT,
    AUTH_URL: '/',
}

export default devconfig;
